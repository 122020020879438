import React from 'react';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player';
// import Img from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import * as styles from '../css/template.module.css';
import StyledHero from '../components/StyledHero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Template = ({ data }) => {
  const { name, body, images, videos } = data.directus.bands[0];
  const mImage = data.directus.bands[0].mainImage?.imageFile.childImageSharp;
  const videoUrls = videos.map(i => ({
    title: i.directus_files_id.title,
    description: i.directus_files_id.description,
    src: `https://sfb.clockworkbee.co.uk/assets/${i.directus_files_id.id}`,
  }));

  const imageItems = [];
  images.forEach((item, index) => {
    const img = `https://sfb.clockworkbee.co.uk/assets/${item.directus_files_id.id}?&width=1024&fit=contain`;
    imageItems.push(
      <img
        style={{ width: '100%', height: '100%' }}
        className={styles.image}
        src={img}
        key={index}
        alt="gallery"
      />
      // <Img
      //   key={index}
      //   fluid={item.directus_files_id.imageFile.childImageSharp.fluid}
      //   alt="single tour"
      //   className={styles.image}
      // />
    );
  });
  return (
    <Layout>
      <SEO title={name} />
      <StyledHero img={mImage?.fluid} />
      <section className={styles.template}>
        <div className={styles.center}>
          <h2 style={{ textAlign: 'center' }}>{name}</h2>
          <div dangerouslySetInnerHTML={{ __html: body }} />
          <h4>Images</h4>
          <div className={styles.images}>{imageItems}</div>
          <h4>Videos</h4>
          <div className={styles.images}>
            {videoUrls.map((item, index) => (
              <div
                className={styles.image}
                style={{ backgroundColor: '#333c56' }}
              >
                <h5
                  style={{
                    marginTop: 20,
                    textAlign: 'center',
                    color: '#c9c9e8',
                  }}
                >
                  {item.title}
                </h5>
                <ReactPlayer
                  width="100%"
                  height={240}
                  controls
                  key={`vid_${index}`}
                  url={item.src}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        onContextMenu: e => e.preventDefault(),
                      },
                    },
                  }}
                />
                <div style={{ height: 128, padding: 16 }}>
                  <div style={{ height: 96, overflowY: 'scroll' }}>
                    {item.description && (
                      <p
                        style={{
                          color: '#b0c4d5',
                          fontSize: '0.85em',
                          fontWeight: 100,
                        }}
                      >
                        {item.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className={styles.info}>
            <p>
              <FaMoneyBillWave className={styles.icon} />
              starting from ${price}
            </p>
            <p>
              <FaMap className={styles.icon} />
              {country}
            </p>
          </div> */}
          {/* <h4>starts on : {start}</h4>
          <h4>duration : {days} days</h4>
          <p className={styles.desc}>{description}</p>
          <h2>daily schedule</h2>
          <div className={styles.journey}>
            {journey.map((item, index) => {
              return <Day key={index} day={item.day} info={item.info} />
            })}
          </div> */}
          <AniLink fade to="/bands" className="btn-primary">
            back to bands
          </AniLink>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query bandInfo($slug: String!) {
    directus {
      bands(filter: { slug: { _eq: $slug } }) {
        id
        name
        slug
        mainImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        videos {
          id
          directus_files_id {
            id
            title
            description
          }
        }
        images {
          id
          directus_files_id {
            id
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        body
      }
    }
  }
`;

export default Template;
